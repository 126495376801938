(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['skins.viewer.gallerymatrix.MatrixGalleryIronSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "imageItem": {
      "skin": "skins.viewer.gallerymatrix.MatrixDisplayerIronSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:30px !important;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:45px !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:45px !important;left:0;"
  }
}
 skins['skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 50,
    "imageItem": {
      "skin": "skins.viewer.gallerymatrix.MatrixDisplayerScotchTapeSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:30px !important;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:45px !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;left:0;right:0;top:10px;bottom:45px !important;"
  }
}
 skins['wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "imageItem": {
      "skin": "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerSeparateTextBoxSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreClr": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "showMoreClr": "color_13",
    "fntds": "font_9"
  },
  "css": {
    "%showMore": "color:[showMoreClr];[fntds]  cursor:pointer;text-decoration:underline !important;height:30px;line-height:30px;position:absolute;bottom:0;left:35%;right:35%;text-align:center;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}
 skins['wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerTextOnCenterSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreColor": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "showMoreColor": "color_11",
    "fntds": "font_9"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[showMoreColor];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "description",
        [],
        {}
      ],
      [
        "a",
        "link",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "SIZE",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "tdr": "URL",
    "trns": "TRANSITION",
    "txt": "TEXT_COLOR",
    "bg1": "BG_COLOR"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "50%",
    "bg": "color_11",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "tdr": "BASE_THEME_DIRECTORY",
    "trns": "opacity 0.5s ease 0s",
    "txt": "color_15",
    "bg1": "color_11"
  },
  "paramsMutators": {
    "bg1": {
      "type": "alpha",
      "value": 0.6
    }
  },
  "css": {
    "%_xxx": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];[rd]  background-color:[bg];[shd]  overflow:hidden;background:url([tdr]net.png) center center repeat;",
    "%": "[rd]  background-color:[bg];[shd]  overflow:hidden;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];overflow:hidden;",
    "%panel": "opacity:0;[trns]  [rd]  background-color:[bg1];color:[txt];text-align:center;padding:20%;position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "overflow:hidden;[rd]",
    "%:hover > %panel": "opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.PolaroidDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {}
    ],
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_wrp"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "description",
        [],
        {}
      ]
    ],
    [
      "a",
      "link",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rd": "5px"
  },
  "css": {
    "%": "background-color:[bg];[rd]",
    "%imageWrapper": "position:absolute !important;top:10 !important;right:10 !important;bottom:10 !important;left:10 !important;",
    "%_wrp": "position:absolute !important;top:10px !important;right:10px !important;bottom:10px !important;left:10px !important;",
    "%panel": "position:absolute;bottom:0;left:0;right:0;color:#fff;padding:10px;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.CircleMatrixGallery'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "bg": "color_1",
    "txt": "color_10"
  },
  "css": {
    "%showMore": "[shd]  background-color:[bg];color:[txt];cursor:pointer;position:absolute;bottom:0;left:35%;right:35%;text-align:center;height:30px;line-height:30px;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.MatrixGallerySkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin",
      "styleGroup": "displayer"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_1",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%showMore": "position:absolute;cursor:pointer;height:20px;line-height:20px;padding:0 5px;bottom:0;background-color:[bg];",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.PolaroidMatrixGallery'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.PolaroidDisplayer",
      "styleGroup": "displayer"
    }
  },
  "css": {
    "%showMore": "position:absolute;cursor:pointer;height:20px;bottom:0;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:20px;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.BlogMatrixGallery'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.BlogMatrixDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerVintage'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadowImgTL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgTR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_bg"
      ],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ],
      [
        "ul",
        null,
        [
          "_panel"
        ],
        {},
        [
          "li",
          null,
          [
            "_ttl"
          ],
          {},
          [
            "h6",
            "title",
            [],
            {}
          ]
        ],
        [
          "li",
          null,
          [
            "_desc"
          ],
          {},
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 80,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "brw": "PADDING_SIZE",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "shd": "0 1px 5px rgba(0, 0, 0, 0.1)",
    "bg": "color_11",
    "brw": "5px",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%_bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]  background:[bg];",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;padding:[brw] [brw] 0 [brw];",
    "%zoom": "position:absolute;top:[brw];bottom:0;left:[brw];right:[brw];background:[bgh];opacity:0;[trans]",
    "%_panel": "height:60px;position:absolute;bottom:0;left:0;right:0;padding:0 10px;text-align:center;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;overflow:hidden;position:absolute;bottom:44px;left:10px;right:10px;height:1.2em;",
    "%description": "color:[txt];[fntds]  white-space:nowrap;overflow:hidden;position:absolute;bottom:28px;left:10px;right:10px;height:1.2em;",
    "%link": "color:[txt];[fntds]  position:absolute;bottom:10px;left:10px;right:10px;text-decoration:underline !important;white-space:nowrap;overflow:hidden;height:1.2em;",
    "%_bgShadow": "background-image:url([tdr]shadow_photo.png);background-repeat:no-repeat;width:168px;height:154px;",
    "%_shadowImgTL": "background-position:0 0;position:absolute;left:-15px;top:-18px;",
    "%_shadowImgTR": "background-position:100% 0;position:absolute;right:-17px;top:-20px;",
    "%_shadowImgBL": "background-position:0 100%;position:absolute;left:-16px;bottom:-16px;",
    "%_shadowImgBR": "background-position:100% 100%;position:absolute;right:-19px;bottom:-16px;",
    "%[data-state~=\"alignLeft\"] %title": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %title": "text-align:center;",
    "%[data-state~=\"alignRight\"] %title": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %description": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %description": "text-align:center;",
    "%[data-state~=\"alignRight\"] %description": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %link": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %link": "text-align:center;",
    "%[data-state~=\"alignRight\"] %link": "text-align:right;",
    "%[data-state~=\"rollover\"]:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerCircleSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%showMore": "color:[txt];[fntds]  cursor:pointer;text-decoration:underline !important;height:30px;line-height:30px;position:absolute;bottom:0;left:35%;right:35%;text-align:center;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerDefaultSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerLiftedShadow",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryPolaroidSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerPolaroidSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerSloopy",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%": "z-index:0;",
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextBelowSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextBelowSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextSlideUpSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreColor": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "showMoreColor": "color_13",
    "fntds": "font_9"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[showMoreColor];[fntds]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTransparentSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreColor": "TEXT_COLOR",
    "fntdsTrans": "FONT"
  },
  "paramsDefaults": {
    "showMoreColor": "color_13",
    "fntdsTrans": "font_9"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[showMoreColor];[fntdsTrans]",
    "%[data-state~=\"fullView\"] %showMore": "display:none;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryVintage'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_pos"
      ],
      {},
      [
        "div",
        "showMore",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerVintage",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%_pos": "height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;",
    "%showMore": "cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:2.3em;left:0;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.PolaroidDisplayerCustomHeightSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreColor": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "showMoreColor": "color_13",
    "fntds": "font_8"
  },
  "css": {
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:25px;left:0;",
    "%showMore": "color:[showMoreColor];[fntds]  position:absolute;left:0;right:0;bottom:0;text-align:center;text-decoration:underline !important;cursor:pointer;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "showMore",
      [],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 25,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.gallerymatrix.TextBottomDisplayerCustomHeightSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "showMoreColor": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "showMoreColor": "color_13",
    "fntds": "font_8"
  },
  "css": {
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:25px;left:0;",
    "%showMore": "color:[showMoreColor];[fntds]  position:absolute;left:0;right:0;bottom:0;text-align:center;text-decoration:underline !important;cursor:pointer !important;",
    "%[data-state~=\"fullView\"] %showMore": "display:none;"
  }
}

        return skins;
    }));